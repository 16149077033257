// UsageGuide.js
import React from "react";
import "./Dashboard.scss";
import "./UsageGuide.scss";
import Sidebar from "./Sidebar";
import LogoNavbar from "./utils/LogoNavbar";

const UsageGuide = () => {
  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="guide" />
      <div className="main-outer">
        <div className="f-col aifs main setup-container">
          <LogoNavbar />
          <div className="f-row aic jcsb header">
            <h1>Usage Guide</h1>
          </div>

          {/* Getting Started Section */}
          <div className="getting-started">
            <h2>Getting Started</h2>
            <ol>
              <li>
                <strong>
                  <a
                    href="https://github.com/apps/devloai/installations/select_target"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ia"
                  >
                    Install devlo
                  </a>
                </strong>{" "}
                to connect your GitHub repositories.
                <br />
              </li>
              <li>
                Create a new pull request or navigate to an existing one in your
                connected repositories.
              </li>
            </ol>
          </div>

          {/* How to Use devlo Section */}
          <div className="how-to-use">
            <h2>How to Use</h2>
            <p>
              To interact with devlo, leave comments on your pull requests
              starting with <code>@devloai</code>. devlo understands natural
              language, so you can ask it to perform tasks or answer questions
              about your code.
            </p>
            <h3>Examples:</h3>
            <ul>
              <li>
                <code>@devloai</code> review this pull request.
              </li>
              <li>
                <code>@devloai</code> identify and fix security vulnerabilities
                in this PR.
              </li>
              <li>
                <code>@devloai</code> generate unit tests for this pull request.
              </li>
              <li>
                <code>@devloai</code> refactor the code in this file for better
                performance.
              </li>
              <li>
                <code>@devloai</code> update the documentation comments for this
                function.
              </li>
              <li>
                <code>@devloai</code> optimize this function.
              </li>
              <li>
                <code>@devloai</code> update the README with setup instructions.
              </li>
            </ul>
            <p>
              devlo will respond to your comments with suggestions, code
              changes, or answers to your questions. Please review devlo's
              suggestions carefully before applying them.
            </p>
            <h3>Notes:</h3>
            <ul>
              <li>
                <strong>Auto-review:</strong> New pull requests will be
                automatically reviewed by devlo. You can turn off this setting
                by visiting the repository's settings.
              </li>
              <li>
                <strong>Review Time:</strong> Reviews typically take up to a
                minute. If you don’t receive a response within 5 minutes, please
                comment again or reach out to us.
              </li>
              <li>
                <strong>Accuracy:</strong> As with any AI, not all generated
                comments will be actionable or accurate. Always review code
                suggestions carefully before applying.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageGuide;
